import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import Select from '@/components/DSE/Select';

export function getFormDefinition(vue) {
  return {
    firstName: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    lastName: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    companyName: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    phoneNumber: {
      type: MDinput,
      props: {
        type: 'tel',
        minlength: 10,
        maxlength: 12,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    addressLineOne: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    addressLineTwo: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: false
      }
    },
    postCode: {
      type: MDinput,
      defaultValue: '',
      props: {
        required: false
      },
      rules: [{ validator: validatorRequire }]
    },
    city: {
      type: MDinput,
      defaultValue: '',
      props: {
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    countryID: {
      type: Select,
      props: {
        caption: 'common.country',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['country/isLoading'],
        options: vue.$store.getters['country/loadedItems']
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
